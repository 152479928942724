<template>
  <md-button to="/delete" class="md-fab md-primary">
    <md-icon>delete</md-icon>
    <md-tooltip md-direction="left">delete documentation</md-tooltip>
  </md-button>
</template>

<script>
export default {
  name: 'DeleteButton'
}
</script>


