<template>
  <md-button to="/upload" class="md-fab md-primary">
    <md-icon>upload</md-icon>
    <md-tooltip md-direction="left">upload documentation</md-tooltip>
  </md-button>
</template>

<script>
export default {
  name: 'UploadButton'
}
</script>


