<template>
  <md-button to="/claim" class="md-fab md-primary">
    <md-icon>generating_tokens</md-icon>
    <md-tooltip md-direction="left">claim token for documentation</md-tooltip>
  </md-button>
</template>

<script>
export default {
  name: 'ClaimButton'
}
</script>


